<div class="main-body row justify-content-sm-center">
  
  <div class="jumbotron col col-sm-8">
    <div class="text-center"><img class="rounded img-fluid" src="assets/splash.jpeg" alt="{{ curriculumName }} Logo" /></div>
  	<h2 class="text-center">{{ appTitle }}</h2>

    <h3 class="text-center my-4">
        You are currently {{isLoggedIn() ? 'Logged In' : 'Not Logged In'}}
    </h3>

  	<div *ngIf="!isLoggedIn()" class="row justify-content-sm-center">
          <a class="btn btn-secondary col-sm-3" role="button"  routerLink="/login">Log In</a>
          <a class="btn btn-secondary col-sm-3 ms-sm-5 mt-1 mt-sm-0" role="button"  routerLink="/register">Sign-Up</a>
    </div>
    
    <div *ngIf="isLoggedIn()" class="justify-content-sm-center" style="border: 1px solid #000; background-color: white; padding:10px;">
        <div class="row justify-content-center">
            <span class="col-sm-12">
                <h4 class="text-center ">Get started with one of the following:</h4>
            </span>
        </div>
        <div class="row justify-content-center">
            <a class="btn btn-primary col-auto mx-1" 
                [routerLink]="['/learn']"
                [queryParams]="{selectMyLevel:true}">
                My Next Step
            </a>

            <a class="btn btn-primary col-auto mx-1" 
                [routerLink]="['/learn']"
                [queryParams]="{requestedCats:[], requestedMods:[], showExplore:true}">
                Explore
            </a>
            <a class="btn btn-primary col-auto mx-1" routerLink="/test">Test</a>
        </div>	

    </div>


  </div> <!-- jumbotron -->
</div>
<div class="row justify-content-sm-center">
    <div *ngIf="schedule && schedule.length > 0" class="d-none d-md-block col-sm-11 mt-2">
        <h3 class="text-center">Schedule</h3>
        <table class="table table-dark table-bordered table-condensed" style="height: 334px;">
            <thead>
                <tr>
                    <th class="text-center small">Sun</th>
                    <th class="text-center small">Mon</th>
                    <th class="text-center small">Tue</th>
                    <th class="text-center small">Wed</th>
                    <th class="text-center small">Thu</th>
                    <th class="text-center small">Fri</th>
                    <th class="text-center small">Sat</th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    *ngFor="let row of schedule">
                    <td 
                        *ngFor="let day of row"
                        class="text-center">
                        <p class="small font-weight-bold">{{day.time}}</p>
                        <p class="small">{{day.description}}</p>
                        <p class="small text-muted">{{day.age}}</p>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>	
    <div *ngIf="schedule && schedule.length > 0" class="d-md-none col-sm-11 mt-2">
        <h3 class="text-center">Schedule</h3>
        <table class="table  table-dark table-bordered table-condensed" style="height: 334px;">
            <thead>
                <tr>
                    <th class="text-center">
                        <button class="btn btn-lg btn-secondary" (click)="decreaseWeekday()">&lt;</button>
                    </th>
                    <th class="text-center">
                        {{["Sun","Mon","Tue","Wed","Thu","Fri","Sat"][weekday]}}
                    </th>
                    <th class="text-center">
                        <button class="btn btn-lg btn-secondary" (click)="increaseWeekday()">&gt;</button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    *ngFor="let row of schedule">
                    <td class="text-center" colspan="3">
                        <p class="font-weight-bold">{{row[weekday].time}}</p>
                        <p >{{row[weekday].description}}</p>
                        <p class="text-muted">{{row[weekday].age}}</p>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>	
</div>

<div class="row">
    <div class="small mx-auto my-2" >App version {{version}} <ng-container *ngIf="!envIsProd">{{envName}}</ng-container></div>
</div>